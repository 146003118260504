<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
    export default {
        page: {
            title: "Discord Commands",
            items: [
            {
                text: "XIVDATA",
                href: "/"
            },
            {
                text: "About",
                href: "/"
            },
            {
                text: "Discord Commands",
                active: true
            }
        ],
        meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader
  },
    }
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <h1>Discord Commands</h1>
                    <hr>
                    You want the Discord Slash-Commands from XIVDATA in your own server? No Problem! Just click this link:<br>
                    <a href="https://discord.com/api/oauth2/authorize?client_id=789266423373496401&scope=applications.commands" target="_blank" class="btn btn-primary"><i class="fab fa-discord"></i>  Add to Discord</a>
                </div>
            </div>
        </div>
    </div>
  </Layout>
</template>